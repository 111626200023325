import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common, isSmallScreen } from '../../../utility/common'
import media from '../../../utility/media'
import PortfolioItem from './PortfolioItem'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  @media (min-width: ${media.md}) {
    margin-top: 100px;
  }
`

const ColOdd = styled.div`
  width: 40%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
`
const ColEven = styled.div`
  width: 40%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`

const ColSmall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

class PortfolioContainer extends Component {
  state = {
    renderChild: false,
  }
  componentDidMount() {
    this.portfolioContainer = TweenMax.from(this.portfolioContainer, 0, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
      onComplete: () => {
        this.setState({ renderChild: true })
      },
    })
  }

  render() {
    const { projects, delay, ...rest } = this.props
    let oddItems = []
    let evenItems = []
    let smScreenItems = []

    projects.map((project, index) => {
      const projectItem = project.project
      const docData = projectItem.document[0].data

      const projectObj = {
        index: index,
        name: docData.name.text,
        title: docData.title.text,
        imgSrc: docData.preview_image.url,
        slug: '/portfolio/' + projectItem.uid,
        external_link: docData.external_link.text,
      }

      const portfolioItem = (
        <PortfolioItem key={index} {...projectObj} {...rest} />
      )

      if (isSmallScreen()) {
        smScreenItems.push(portfolioItem)
        return
      }

      if ((index + 1) % 2 === 0) {
        // If Even
        evenItems.push(portfolioItem)
      } else {
        oddItems.push(portfolioItem)
      }
    })

    return (
      <Container innerRef={div => (this.portfolioContainer = div)}>
        {this.state.renderChild ? (
          <>
            {isSmallScreen() ? (
              <ColSmall>
                {smScreenItems.map(item => {
                  return item
                })}
              </ColSmall>
            ) : (
              <>
                <ColOdd>
                  {oddItems.map(item => {
                    return item
                  })}
                </ColOdd>
                <ColEven>
                  {evenItems.map(item => {
                    return item
                  })}
                </ColEven>
              </>
            )}
          </>
        ) : null}
      </Container>
    )
  }
}

export default PortfolioContainer
