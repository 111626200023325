import React, { Component } from 'react'
import styled from 'styled-components'
import { TimelineMax, TweenMax } from 'gsap/TweenMax'
import Reveal from 'react-reveal'
import theme from '../../../utility/theme'
import media from '../../../utility/media'
import { common } from '../../../utility/common'
import Bar from '../../Bar'

const StyledPortfolioItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  @media (min-width: ${media.md}) {
    margin-bottom: 80px;
  }
`

const PortfolioLink = styled.a`
  width: 100%;
  display: block;
`

const PortfolioImageContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  background: ${theme.colorGrey};
  margin-bottom: 15px;
  position: relative;

  @media (min-width: ${media.md}) {
    margin-bottom: 30px;
  }
`

const PortfolioImageBefore = styled.div`
  width: 100%;
  height: 0%;
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colorPrimary};
`
const PortfolioImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 99.9%;
  height: 99.9%;
  position: absolute;
  z-index: 10;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`

const PortfolioText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PortfolioNameContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
`

const BarContainer = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const PortfolioName = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 24px;
  color: #fff;
  margin-left: 12px;
`

const PortfolioTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.6;
  color: ${theme.colorSecondary};
  @media (min-width: ${media.md}) {
    font-size: 22px;
    line-height: 36px;
  }
`

class PortfolioItem extends Component {
  state = {
    height: 400,
    canAnimationStart: false,
  }

  constructor(props) {
    super(props)
    this.image = null
  }

  setImageHeight = () => {
    const height =
      (this.imageContainer.clientWidth / this.image.naturalWidth) *
      this.image.naturalHeight

    this.setState({
      height,
    })
  }

  animatePortfolioItem = () => {
    this.portfolioTimeline = new TimelineMax()
    this.portfolioTimeline.add(
      TweenMax.from(this.imageContainer, common.duration, {
        autoAlpha: 0,
        top: 10,
      })
    )
    this.portfolioTimeline.add(
      TweenMax.to(this.imageBefore, common.duration, {
        height: '100%',
      })
    )
    this.portfolioTimeline.add(
      TweenMax.from(this.image, common.duration, {
        autoAlpha: 0,
      })
    )
    this.portfolioTimeline.add(
      TweenMax.to(this.imageBefore, common.duration, {
        top: 0,
        height: 0,
      })
    )

    this.portfolioTimeline.add(
      TweenMax.from(this.nameContainer, common.duration, {
        autoAlpha: 0,
        top: 10,
      })
    )
    this.portfolioTimeline.add(
      TweenMax.from(this.title, common.duration, { autoAlpha: 0, top: 10 })
    )
  }

  onMouseEnterHandler = () => {
    this.setState({
      canAnimationStart: true,
    })
  }

  onMouseLeaveHandler = () => {
    this.setState({
      canAnimationStart: false,
    })
  }

  componentWillUnmount() {
    this.portfolioTimeline = null
  }

  render() {
    const { name, title, imgSrc, slug, external_link, startPageChangingHandler } = this.props
    const { height, canAnimationStart } = this.state


    return (
      <Reveal onReveal={() => this.animatePortfolioItem()}>
        <StyledPortfolioItem>
          <PortfolioLink
            href={slug}
            href={external_link ? external_link : slug}
            target={external_link ? "_blank" : "_self"}
            onClick={e => {
              if (!external_link){
                e.preventDefault()
                startPageChangingHandler(slug)
              }
            }}

            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
          >
            <PortfolioImageContainer
              innerRef={div => (this.imageContainer = div)}
              height={height}
            >
              <PortfolioImageBefore
                innerRef={div => (this.imageBefore = div)}
              />
              <PortfolioImage
                src={imgSrc}
                alt={title}
                innerRef={image => {
                  this.image = image
                }}
                onLoad={this.setImageHeight}
              />
            </PortfolioImageContainer>
            <PortfolioText innerRef={div => (this.textContainer = div)}>
              <PortfolioNameContainer
                innerRef={div => (this.nameContainer = div)}
              >
                <BarContainer>
                  <Bar canAnimationStart={canAnimationStart} />
                </BarContainer>
                <PortfolioName>{name}</PortfolioName>
              </PortfolioNameContainer>

              <PortfolioTitle innerRef={div => (this.title = div)}>
                {title}
              </PortfolioTitle>
            </PortfolioText>
          </PortfolioLink>
        </StyledPortfolioItem>
      </Reveal>
    )
  }
}

export default PortfolioItem
