import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import PageTag from '../components/page-components/PageTag'
import Title from '../components/page-components/Title'
import PortfolioContainer from '../components/page-components/portfolio/PortfolioContainer'

const Container = styled.div`
  width: 100%;
`

const StyledHeader = styled.div`
  width: 100%;
`

class Portfolio extends Component {
  render() {
    const { data, isFirstLoad, timingOffset, ...rest } = this.props
    const nodeData = data.allPrismicAllProject.edges[0].node.data
    const pageTag = nodeData.page_name.text
    const pageTitle = nodeData.title.text
    const projects = nodeData.projects

    return (
      <>
        <Helmet>
          <title>Portfolio of Abdus Salam, Freelance UI/UX designer & Front-end Developer</title>
          <meta name="description" content="My recent work on User Experience Design, Website UI Design, Mobile App UI(Android / iOS) Design, Saas App Design, Dashboard / Admin Panel Design." />
        </Helmet>
        <Container>
          <StyledHeader>
            <PageTag delay={timingOffset}>{pageTag}</PageTag>
            <Title delay={timingOffset + 0.3}>{pageTitle}</Title>
          </StyledHeader>
          <PortfolioContainer
            delay={timingOffset + 0.5}
            {...rest}
            projects={projects}
          />
        </Container>
      </>
    )
  }
}

export const query = graphql`
  query {
    allPrismicAllProject {
      edges {
        node {
          uid
          data {
            page_name {
              text
            }
            title {
              text
            }
            projects {
              project {
                uid
                document {
                  data {
                    name {
                      text
                    }
                    title {
                      text
                    }
                    external_link {
                      text
                    }
                    preview_image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Portfolio
